<template>
  <!-- 小区动态 -->
  <div class="community">
    <my-notice :id="3" />
  </div>
</template>

<script>
export default {
  name: "community",
  components: {},
};
</script>
